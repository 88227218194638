import '../Styles/Home.scss'
function Home() {

  return (
    <div className="body-container">
      <div className="home-header">
        <div className="waviy">
          <span style={{"--i":1}}>C</span>
          <span style={{"--i":2}}>o</span>
          <span style={{"--i":3}}>m</span>
          <span style={{"--i":4}}>i</span>
          <span style={{"--i":5}}>n</span>
          <span style={{"--i":6}}>g</span>
          <span>&nbsp;</span>
          <span style={{"--i":7}}>S</span>
          <span style={{"--i":8}}>o</span>
          <span style={{"--i":9}}>o</span>
          <span style={{"--i":10}}>n</span>
          <span style={{"--i":11}}>.</span>
          <span style={{"--i":12}}>.</span>
          <span style={{"--i":13}}>.</span>
        </div>
      </div>
    </div>
  )
}

export default Home